
import { ChallengeClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class Dashboard extends Vue {

    challenges: OM.StageVM[] = [];
    
    created() {
        ChallengeClient.getAllStages()
        .then(x => {
            this.challenges = x;
        })
    }

}
